import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/datamigrators.github.io/datamigrators.github.io/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`This page describes Data Migrators’ secure System Development Life Cycle policy.`}</p>
    </PageDescription>
    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Overview</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Purpose</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Scope</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Policy</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Policy Compliance</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Exceptions</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Non-compliance</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Related Documents</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Definitions and Terms</AnchorLink>
    </AnchorLinks>
    <h2>{`Overview`}</h2>
    <p>{`See purpose.`}</p>
    <h2>{`Purpose`}</h2>
    <p>{`The purpose of this policy is to define requirements for system security planning and management to improve protection of Data Migrators information system resources.`}</p>
    <h2>{`Scope`}</h2>
    <p>{`This policy applies to all Data Migrators departments, employees, and affiliated organizations that use Data Migrators information technology resources to create, access, store or manage Organisational Data to perform their business functions. The requirement applies to enterprise information systems or systems that require special attention to security due to the risk of harm resulting from loss, misuse, or unauthorized access to or modification of the information therein.`}</p>
    <h2>{`Policy`}</h2>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Security`}</strong>{` - Security has to be considered at all stages of the life cycle of an information system (i.e., feasibility, planning, development, implementation, maintenance, and retirement) in order to:`}
        <ol parentName="li">
          <li parentName="ol">{`ensure conformance with all appropriate security requirements,`}</li>
          <li parentName="ol">{`protect sensitive information throughout its life cycle,`}</li>
          <li parentName="ol">{`facilitate efficient implementation of security controls,`}</li>
          <li parentName="ol">{`prevent the introduction of new risks when the system is modified, and`}</li>
          <li parentName="ol">{`ensure proper removal of data when the system is retired. This policy provides guidance to ensure that systems security is considered during the Acquisition, Development and Maintenance and Testing Stages of an information system’s life cycle.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Strategy`}</strong>{` - The Director of Information Security defines the strategy for the appropriate security of all software and web applications, as well as to monitor, establish and enforce remediation timelines and sanctions for non-compliant systems. The Information Security Office (ISO) will establish security standards for the acquisition, development, deployment and maintenance of all software and web applications handling sensitive information or that are accessible from off site. These standards will ensure that fundamental security principles are incorporated, such as those generally incorporated into the National Institute of Standard Technology (NIST), Information Technology Infrastructure Library (ITIL) and System Administration and Network Security (SANS) frameworks.`}</li>
      <li parentName="ol"><strong parentName="li">{`Acquisition`}</strong>{` - All software and web application acquisitions or upgrades involving handling of information and/or access must be reviewed and approved by the Director of Information Security or their designee(s) in writing prior to purchase or implementation. All contracts for work involving handling of information and/or access must also be reviewed and approved by the Director of Information Security or their designee(s) in writing prior to acquisition.`}
        <ol parentName="li">
          <li parentName="ol"><strong parentName="li">{`Vendor acquisitions`}</strong>{` - If an enterprise information system or component of that system is acquired from an external vendor, written documentation must be provided that specifies how the product meets the security requirements of this policy and any special security requirements of the system. The vendor must allow testing of the system’s security controls by the ISO or an independent third party, if needed.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Development`}</strong>{` - All application and web developers must familiarize themselves and follow the site Application Development Standards to ensure they are employing secure procedures for any application or web development involving organisational data. All application code for such applications must be reviewed and approved in writing by the ISO prior to deployment. All significant changes in application code must also be reviewed for vulnerabilities prior to deployment. All applications or web processes handling, processing or storing critical or sensitive organisational information must be housed only within secured data centers approved by the Director of Information Security  and run on secured systems meeting all applicable security policies and standards approved by the ISO.`}
        <ol parentName="li">
          <li parentName="ol"><strong parentName="li">{`System security plans and documentation`}</strong>{` - System security plans and documentation must be prepared for all enterprise information systems or other systems under development that require special attention to security due to the risk of harm resulting from loss, misuse, or unauthorized access to or modification of the information therein. Such plans should provide an overview of the security requirements of the system and describe the controls in place or planned for meeting those requirements through all stages of the system’s life cycle. When the system is modified in a manner that affects security, system documentation must be updated accordingly.`}</li>
          <li parentName="ol"><strong parentName="li">{`Separate environments`}</strong>{` - System development, testing, and production should be performed in separate environments.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Maintenance and Testing`}</strong>{` - Access to source code and other critical system resources during testing, development, or production must be limited to only authorized personnel with an authorized work-related need.`}
        <ol parentName="li">
          <li parentName="ol"><strong parentName="li">{`Test data`}</strong>{` - Testing of enterprise information systems should be done with fabricated data that mimics the characteristics of the real data, or on copies of real data with any confidential data appropriately sanitized. Testing should not be done on live data due to the threat to its confidentiality and/or integrity. Testing that requires the use of live data or confidential data must have appropriate security controls employed.`}</li>
          <li parentName="ol"><strong parentName="li">{`Vulnerability management`}</strong>{` - An assessment of the system’s security controls and a vulnerability assessment that seeks to identify weaknesses that may be exploited must be performed on all new enterprise information systems or ones undergoing significant change before moving them into production. Periodic vulnerability assessments must also be performed on production enterprise information systems and appropriate measures taken to address the risk associated with identified vulnerabilities. Vulnerability notifications from vendors and other appropriate sources should be monitored and assessed for all systems and applications associated with enterprise information system.`}</li>
        </ol>
      </li>
    </ol>
    <h3>{`Responsibilities`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Information Security Office`}</strong>{` (ISO) - Coordinates the development, review, and approval of system security plans as well as the identification, implementation, and assessment of common security controls; oversees periodic vulnerability assessments for enterprise information systems; and coordinates implementation of other assessments as needed with information system security administrators.`}</li>
      <li parentName="ul"><strong parentName="li">{`System Administrator`}</strong>{` - Ensures the implementation of appropriate operational security controls for an information system; coordinates with the ISO in the identification, implementation, and assessment of common security controls; plays an active role in developing and updating a system security plan and coordinating with an information system owner any changes to the system and assessing the security impact of those changes. This role may be filled by someone directly involved with the development, maintenance, and/or operation of the information system.`}</li>
    </ul>
    <h2>{`Policy Compliance`}</h2>
    <p>{`All development is required to comply with this policy.`}</p>
    <h2>{`Exceptions`}</h2>
    <p>{`None.`}</p>
    <h2>{`Non-compliance`}</h2>
    <p>{`Violations of this policy may require the removal of any unapproved resources and may subject the violator to disciplinary actions, up to or including termination of employment.`}</p>
    <h2>{`Related Documents`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "information_lifecycle_management_policy"
        }}>{`Information Security Policy`}</a></li>
    </ul>
    <h2>{`Definitions and Terms`}</h2>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Confidential data`}</strong>{` - Highly sensitive data intended for limited, specific use by a workgroup, department, or group of individuals with a legitimate need-to-know.`}</li>
      <li parentName="ul"><strong parentName="li">{`Enterprise information system`}</strong>{` - An information system and/or server providing services commonly needed by Data Migrators.`}</li>
      <li parentName="ul"><strong parentName="li">{`Information Technology Infrastructure Library`}</strong>{` (ITIL) - Provides a cohesive set of best practice to Information Technology Service Management.`}</li>
      <li parentName="ul"><strong parentName="li">{`Live data`}</strong>{` - Data accessible to users through systems that are in production environment (i.e., live)`}</li>
      <li parentName="ul"><strong parentName="li">{`National Institute of Standard Technology`}</strong>{` (NIST) - NIST is the federal technology agency that works with industry to develop and apply technology, measurements, and standards.`}</li>
      <li parentName="ul"><strong parentName="li">{`Sanitized`}</strong>{` - Is the process of removing sensitive information from a document or other medium, so that it may be distributed to a broader audience.`}</li>
      <li parentName="ul"><strong parentName="li">{`System Administration and Network Security`}</strong>{` (SANS) - SANS is a private U.S. company that specializes in information security and cybersecurity training, and security design and implementation best practices.`}</li>
      <li parentName="ul"><strong parentName="li">{`Sensitive`}</strong>{` - Any information that can be used for the purpose of identification.`}</li>
      <li parentName="ul"><strong parentName="li">{`Organisational Data`}</strong>{` - Any data related to Data Migrators functions that are stored on Data Migrators information technology systems or related to organisational processes. This applies to any format or media, and not limited to electronic data.`}</li>
      <li parentName="ul"><strong parentName="li">{`Vulnerability`}</strong>{` - A flaw or weakness in system security procedures, design, implementation, or internal controls that could be exercised (accidentally triggered or intentionally exploited) and result in a security breach or a violation of the system’s security policy.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      